<template>
  <div>
    <Header />
    <v-main>
      <div class="menu-sidebar no-print">
        <Sidebar />
      </div>
      <div class="app-content">
        <PastDue />
        <router-view></router-view>
      </div>
    </v-main>
  </div>
</template>
<script>
import Header from "@/components/layout/Header.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import PastDue from "@/components/merchant/subscription/PastDue.vue";
export default {
  name: "AppLayout",
  components: {
    Header,
    Sidebar,
    PastDue,
  },
};
</script>

<style></style>
