export default {
  computed: {
    isOwner() {
      if (this.$store.getters['auth/getCurrentUser'])
        return this.$store.getters['auth/getCurrentUser'].isOwner;
      else return false;
    },
    getCurrentMerchant() {
      return this.$store.getters['merchant/getCurrentMerchant'];
    },
    currentMerchantId() {
      if (this.$store.getters['merchant/getCurrentMerchant']) {
        return this.$store.getters['merchant/getCurrentMerchant']?.id;
      } else {
        return null;
      }
    },
    getTeamMerchants() {
      return this.$store.getters['merchant/getTeamMerchants'].length > 1
        ? this.$store.getters['merchant/getTeamMerchants']
        : null;
    },
    merchantTeam() {
      return this.$store.getters['merchant/getCurrentMerchant']?.team;
    },
    getCurrentUser() {
      return this.$store.getters['auth/getCurrentUser'];
    },
    activeSubscription() {
      return this.$store.getters['merchant/getSubscriptionStatus'];
    },
  },
};
