<template>
  <div>
    <Header />
    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue';
export default {
  name: 'ProfileLayout',
  components: {
    Header,
  },
};
</script>

<style></style>
