import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#000080",
        secondary: "#0029ff",
        accent: "#000080",
        magicTitle: {
          base: "#474863",
          lighten1: "#a9abc0",
          darken1: "#6b6c96",
        },
        magicGray: "#A8A8B4",
        magicYellow: "#FFC200",
        mwBodyGray: "#39394E",
        mwBodyGray1: "#666666",
        toolTitle: "#191B5F",
        print: "#000000",
        status: {
          base: "#2F80ED",
          lighten1: "#2F80ED", //new
          lighten2: "#0029FF", //confirmed
          lighten3: "#E3AB04", //processing
          lighten4: "#670DFF", //readyToDeliver
          lighten5: "#019BA5", //pickedUp
          lighten6: "#88AA02", //inTransit
          lighten7: "#04860A", //delivered
          lighten8: "#F21E1E", //cancelled
          lighten9: "#000080", //closed
        },
      },
    },
  },
});
