<template>
  <v-main class="public-view">
    <router-view></router-view>
  </v-main>
</template>
<script>
export default {
  name: "PublicLayout",
};
</script>

<style></style>
