var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.activeSubscription || {}).status == 'past_due' ||
    (_vm.activeSubscription || {}).status == 'canceled' ||
    (_vm.activeSubscription || {}).status == 'pending' ||
    (_vm.activeSubscription || {}).status == 'halted'
  )?_c('div',[(_vm.activeSubscription.mode == 'razorpay')?_c('v-alert',{attrs:{"color":"warning","dark":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"white"}},[_vm._v(" mdi-alert-outline ")]),(
        _vm.activeSubscription.merchant_subscribed &&
        (_vm.activeSubscription || {}).status == 'pending'
      )?_c('span',[_vm._v(" You are about to lose your magic. Please make the payment to retain your services and magical powers. "),(_vm.activeSubscription.mode == 'razorpay')?_c('router-link',{attrs:{"to":{ name: 'merchant-edit-subscribe' }}},[_vm._v("Go to Subscription")]):_vm._e()],1):_vm._e(),((_vm.activeSubscription || {}).status == 'halted')?_c('span',[_vm._v(" This business has lost its magic. Clear the outstanding dues to revive your services and magic. ")]):_vm._e(),((_vm.activeSubscription || {}).status == 'canceled')?_c('span',[_vm._v(" This business has lost its magic. Please make the payment to retain your services and magical powers. ")]):_vm._e()],1):_vm._e(),(_vm.activeSubscription.mode == 'stripe')?_c('v-alert',_vm._g({class:{ pointer: !_vm.activeSubscription.hosted_invoice_url },attrs:{"color":"warning","dark":""}},
      !_vm.activeSubscription.hosted_invoice_url ? { click: _vm.navigateBilling } : {}
    ),[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"white"}},[_vm._v(" mdi-alert-outline ")]),(_vm.activeSubscription.merchant_subscribed)?_c('span',[_vm._v(" You are about to lose your magic. Please make the payment to retain your services and magical powers. ")]):_c('span',[_vm._v(" "+_vm._s(_vm.activeSubscription.hosted_invoice_url ? "This business has lost its magic. Clear the outstanding dues to revive your services and magic." : "Thank you for clearing the outstanding. Now please resubscribe to restart Magicwant.")+" ")])],1),_c('v-col',{staticClass:"shrink"},[(_vm.activeSubscription.hosted_invoice_url)?_c('div',[(
              Object.keys(_vm.activeSubscription.hosted_invoice_url).length > 1
            )?_c('div',[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Pay Now "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,3559693181)},[_c('v-list',_vm._l((_vm.activeSubscription.hosted_invoice_url),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-action',[_c('v-btn',{staticClass:"primary",attrs:{"outlined":""}},[_c('a',{staticClass:"white--text",attrs:{"target":"_blank","href":item.url}},[_vm._v("Invoice ("+_vm._s(_vm._f("moment")(item.date.toDate(),"DD MMM YYYY"))+")")])])],1)],1)}),1)],1)],1)]):_c('div',[_c('v-btn',{staticClass:"primary"},[_c('a',{staticClass:"white--text",attrs:{"target":"_blank","href":_vm.activeSubscription.hosted_invoice_url[
                    Object.keys(_vm.activeSubscription.hosted_invoice_url)[0]
                  ].url}},[_vm._v("Pay Now")])])],1)]):_vm._e()])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }