const auth = {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_CURRENT_USER: (state, user) => {
      state.user = user;
    },
  },
  actions: {
    async defaultUser({ state, commit, dispatch }) {
      if (!state.user) {
        if (window.localStorage.getItem("MWUser")) {
          let currentUser = JSON.parse(
            window.atob(window.localStorage.getItem("MWUser"))
          );
          if (currentUser) {
            dispatch("currentUser", currentUser);
          }
        }
      }
    },
    currentUser({ commit }, user) {
      if (user) {
        commit("SET_CURRENT_USER", user);
      }
    },
  },
  getters: {
    getCurrentUser(state) {
      return state.user;
    },
  },
};

export { auth };
