import Vue from "vue";
import JsonExcel from "vue-json-excel";
import VueMeta from 'vue-meta';
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";

import { auth } from "@/firebase/init";
import { onAuthStateChanged } from "firebase/auth";

import authMixin from "@/mixins/auth";
import coreMixin from "@/mixins/core";
import facilitiesMixin from "@/mixins/facilities";
import browserDetect from "vue-browser-detect-plugin";
import VueSocialSharing from "vue-social-sharing";
// testing mabook push
Vue.use(VueMeta);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.use(VueSocialSharing);
Vue.use(browserDetect);
Vue.mixin(coreMixin);
Vue.mixin(authMixin);
Vue.mixin(facilitiesMixin);

Vue.component("downloadExcel", JsonExcel);

Vue.use(VueAxios, axios);

Vue.use(require("vue-moment"));

import "@/scss/global.scss";
import "@/scss/menu.scss";

Vue.config.productionTip = false;

let app = null;

onAuthStateChanged(auth, () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      beforeCreate() {
        this.$store.dispatch("initialiseStore");
      },
      render: (h) => h(App),
    }).$mount("#app");
  }
});

String.prototype.capitalize = function () {
  if (this) {
    return (this.charAt(0).toUpperCase() + this.slice(1)).replace(/_/g, " ");
    // this.charAt(0).toUpperCase() + this.slice(1);
  }
};

Number.prototype.round = function (places = 2) {
  if (this) {
    places = Math.pow(10, places);
    return Math.round(this * places) / places;
  } else return 0;
};

String.prototype.removeSpace = function () {
  if (this) {
    return this.split(" ").join("");
    // this.charAt(0).toUpperCase() + this.slice(1);
  }
};
