export default {
  methods: {
    getFacilities() {
      let facilitiesObj = this.$store.getters["facilities/getFacilities"];

      if (!facilitiesObj) return false;

      let facilities = [];

      for (const facilityId in facilitiesObj) {
        facilities.push(facilitiesObj[facilityId]);
      }

      return facilities;
    },
    getFacility(id) {
      let facilitiesObj = this.$store.getters["facilities/getFacilities"];

      if (!facilitiesObj) return false;

      return facilitiesObj[id];
    },

    getPrivileges(fId, privilege) {
      if (this.isOwner) {
        return true;
      }
      let privileges = this.$store.getters["facilities/getPrivileges"];

      if (!privileges[fId]) return false;

      return privileges[fId].includes(privilege);
    },

    getRawMaterials(id) {
      let materialObj = this.$store.getters["facilities/getRawMaterials"];

      if (!materialObj[id]) return [];

      return materialObj[id];
    },
  },
};
