<template>
  <v-app-bar v-if="user" app flat class="white no-print">
    <div class="d-flex align-center">
      <v-img
        alt="magicwant Logo"
        src="@/assets/images/magicwant-logo-beta.png"
        transition="fade-transition"
        width="215"
        contain />
    </div>
    <v-spacer></v-spacer>
    <div v-if="getCurrentMerchant">
      <v-list v-if="isOwner">
        <v-list-item>
          <v-list-item-avatar tile>
            <img
              v-if="getCurrentMerchant.logo"
              :src="getCurrentMerchant.logo"
              class="avatar medium" />
            <img
              v-else
              src="@/assets/images/facility-placeholder.jpg"
              class="avatar medium" />
          </v-list-item-avatar>
          <v-list-item-content class="d-block">
            <router-link :to="{ name: 'merchant' }">
              <v-list-item-title>{{
                getCurrentMerchant.name
              }}</v-list-item-title>
            </router-link>
            <v-list-item-subtitle class="mt-1"
              ><span class="font-weight-regular">Business Id: </span>
              <span class="font-weight-medium">{{
                generateId("MWBUS_", currentMerchantId)
              }}</span></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-avatar tile>
            <img
              v-if="getCurrentMerchant.logo"
              :src="getCurrentMerchant.logo"
              class="avatar medium" />
            <img
              v-else
              src="@/assets/images/facility-placeholder.jpg"
              class="avatar medium" />
          </v-list-item-avatar>
          <v-list-item-content>
            <router-link :to="{ name: 'merchant' }">
              <v-list-item-title>{{
                getCurrentMerchant.name
              }}</v-list-item-title>
            </router-link>
          </v-list-item-content>
          <v-menu
            v-if="getTeamMerchants"
            left
            bottom
            offset-y
            origin="top right"
            transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(merchant, i) in getTeamMerchants"
                :key="'tm' + i"
                @click="selectDefaultMerchant(merchant)">
                <v-list-item-avatar tile>
                  <img
                    v-if="merchant.logo"
                    :src="merchant.logo"
                    class="avatar medium" />
                  <img
                    v-else
                    src="@/assets/images/facility-placeholder.jpg"
                    class="avatar medium" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ merchant.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <!--
      <v-list-item>
        <v-list-item-avatar>
          <img
            src="@/assets/images/user-avatar-placeholder.png"
            class="avatar medium"
          />
        </v-list-item-avatar>
        <v-list-item-content v-if="getCurrentUser">
          <router-link :to="{ name: 'merchant' }">
            <v-list-item-title>
              {{ getCurrentUser.displayName }}
            </v-list-item-title>
          </router-link>
        </v-list-item-content>
      </v-list-item>
      -->
      </v-list>
    </div>
    <v-menu
      left
      bottom
      offset-y
      origin="top right"
      transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="isOwner">
          <router-link :to="{ name: 'merchant-edit' }">
            <v-list-item-title>
              <v-icon>mdi-account-settings</v-icon>Settings</v-list-item-title
            >
          </router-link>
        </v-list-item>
        <v-list-item>
          <a @click="logout">
            <v-list-item-title>
              <v-icon>mdi-logout</v-icon>Logout</v-list-item-title
            >
          </a>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { auth } from "@/firebase/init";
import { signOut } from "firebase/auth";

export default {
  name: "Header",
  data() {
    return {
      user: null,
    };
  },
  created() {
    if (auth.currentUser && window.localStorage.getItem("MWUser")) {
      this.user = JSON.parse(
        window.atob(window.localStorage.getItem("MWUser"))
      );
    }
  },
  mounted() {
    if (document.getElementById("zsiqchat")) {
      return;
    }

    /* let src = "@/assets/js/salesiq.js";
    let script = document.createElement("script");
    script.setAttribute("src", src);
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "zsiqchat");
    document.head.appendChild(script); */
/*
    let innerCode =
      'var $zoho = $zoho || {}; $zoho.salesiq = $zoho.salesiq || { widgetcode: "0d222957d32dbbe1574148fb6914e3859302132eebd1a4ca30a0dc54dfadd9dda81d6b547a35c109951d24f6be71d2d0", values: {}, ready: function () { } }; var d = document; s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true; s.src = "https://salesiq.zoho.in/widget"; t = d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s, t);';
    var script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "zsiqchat");
    script.innerHTML = innerCode;
    document.body.appendChild(script);
   */
  },
  beforeDestroy() {},
  methods: {
    logout() {
      let el = document.getElementById("zsiqchat");
      if (el) {
        el.remove();
      }
      signOut(auth).then(() => {
        window.localStorage.clear();
        this.$store.dispatch("clearAuth");
        this.user = null;
        this.$router.push("/");
      });
    },
    selectDefaultMerchant(merchant) {
      window.localStorage.setItem(
        "MWCurrentMerchant",
        window.btoa(JSON.stringify(merchant.id))
      );

      this.$store.dispatch("merchant/currentTeamMerchant", merchant);
      
      if (this.$route.name !== "merchant") {
        this.$router.push({ name: "merchant" });
      }
    },
  },
};
</script>

<style></style>
