<template>
  <div
    v-if="
      (activeSubscription || {}).status == 'past_due' ||
      (activeSubscription || {}).status == 'canceled' ||
      (activeSubscription || {}).status == 'pending' ||
      (activeSubscription || {}).status == 'halted'
    ">
    <v-alert v-if="activeSubscription.mode == 'razorpay'" color="warning" dark>
      <v-icon medium color="white" class="mr-2"> mdi-alert-outline </v-icon>

      <span
        v-if="
          activeSubscription.merchant_subscribed &&
          (activeSubscription || {}).status == 'pending'
        ">
        You are about to lose your magic. Please make the payment to retain your
        services and magical powers.
        <router-link
          v-if="activeSubscription.mode == 'razorpay'"
          :to="{ name: 'merchant-edit-subscribe' }"
          >Go to Subscription</router-link
        >
      </span>
      <!--  -->
      <span v-if="(activeSubscription || {}).status == 'halted'">
        This business has lost its magic. Clear the outstanding dues to revive
        your services and magic.
      </span>

      <span v-if="(activeSubscription || {}).status == 'canceled'">
        This business has lost its magic. Please make the payment to retain your
        services and magical powers.
      </span>
    </v-alert>

    <v-alert
      v-if="activeSubscription.mode == 'stripe'"
      color="warning"
      dark
      v-on="
        !activeSubscription.hosted_invoice_url ? { click: navigateBilling } : {}
      "
      :class="{ pointer: !activeSubscription.hosted_invoice_url }">
      <v-row align="center">
        <v-col class="grow">
          <v-icon medium color="white" class="mr-2"> mdi-alert-outline </v-icon>

          <span v-if="activeSubscription.merchant_subscribed">
            You are about to lose your magic. Please make the payment to retain
            your services and magical powers.
          </span>
          <!--  -->
          <span v-else>
            {{
              activeSubscription.hosted_invoice_url
                ? "This business has lost its magic. Clear the outstanding dues to revive your services and magic."
                : "Thank you for clearing the outstanding. Now please resubscribe to restart Magicwant."
            }}
          </span>
        </v-col>
        <v-col class="shrink">
          <div v-if="activeSubscription.hosted_invoice_url">
            <div
              v-if="
                Object.keys(activeSubscription.hosted_invoice_url).length > 1
              ">
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                      Pay Now <v-icon right dark> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(
                        item, index
                      ) in activeSubscription.hosted_invoice_url"
                      :key="index">
                      <v-list-item-action>
                        <v-btn class="primary" outlined
                          ><a
                            class="white--text"
                            target="_blank"
                            :href="item.url"
                            >Invoice ({{
                              item.date.toDate() | moment("DD MMM YYYY")
                            }})</a
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
            <div v-else>
              <v-btn class="primary"
                ><a
                  class="white--text"
                  target="_blank"
                  :href="
                    activeSubscription.hosted_invoice_url[
                      Object.keys(activeSubscription.hosted_invoice_url)[0]
                    ].url
                  "
                  >Pay Now</a
                ></v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-alert>
  </div>
  <!--  -->
</template>

<script>
export default {
  created() {},

  methods: {
    navigateBilling() {
      this.$router.push({ name: "merchant-edit", params: { step: "billing" } });
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}
</style>
