import { db_9 } from "@/firebase/init";
import userAdoptionMixin from "@/mixins/user_adoption_and_support";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
const merchant = {
  namespaced: true,
  state: {
    currentMerchant: null,
    subscriptionStatus: null,
    subscribedTools: null,
    teamMerchants: [],
    ipLocation: null,
    toolsLoaded: false,
  },
  mutations: {
    SET_CURRENT_MERCHANT: (state, merchant) => {
      state.currentMerchant = merchant;
    },
    SET_CURRENT_MERCHANT_TEAM: (state, team) => {
      state.currentMerchant.team = team;
    },
    SET_SUBSCRIPTION_STATUS: (state, status) => {
      state.subscriptionStatus = status;
    },
    SET_SUBSCRIBED_TOOLS: (state, tools) => {
      state.subscribedTools = tools;
    },
    SET_TEAM_MERCHANTS: (state, merchant) => {
      if (merchant) {
        state.teamMerchants.push(merchant);
      } else {
        state.teamMerchants = [];
      }
    },
    SET_IP_LOCATION: (state, loc) => {
      if (loc) {
        state.ipLocation = loc;
      }
    },

    SET_TOOLS_LOADED: (state, value) => {
      state.toolsLoaded = value;
    },
  },
  actions: {
    async defaultMerchant({ state, dispatch }) {
      if (!state.currentMerchant) {
        if (window.localStorage.getItem("MWCurrentMerchant")) {
          let currentMerchant = JSON.parse(
            window.atob(window.localStorage.getItem("MWCurrentMerchant"))
          );
          if (currentMerchant) {
            var data = {
              id: currentMerchant,
            };
            //   commit('SET_CURRENT_MERCHANT', data);
            dispatch("currentMerchant", currentMerchant);
          }
        }
      }
    },
    async setSubscribedTools({ commit }, merchant) {
      const productRef = collection(
        db_9,
        "subscriptions",
        merchant,
        "products"
      );
      getDocs(productRef).then((products) => {
        if (!products.empty) {
          var tools = {};
          products.forEach((product) => {
            if (!tools[product.data().facility_id]) {
              tools[product.data().facility_id] = [];
            }
            var data = {
              id: product.id,
              tool: product.data().feature,
              subscribed_on: product.data().subscribed_date.toDate(),
            };
            tools[product.data().facility_id].push(data);
          });
          commit("SET_SUBSCRIBED_TOOLS", tools);
        }
        commit("SET_TOOLS_LOADED", true);
      });
    },
    async setSubscriptionStatus({ commit }, merchant) {
      const subsRef = doc(db_9, "subscriptions", merchant);
      onSnapshot(subsRef, (subscribe) => {
        if (subscribe.exists()) {
          let subscriptions = subscribe.data();
          let status =
            (subscriptions?.merchant_subscribed) ||
            (subscriptions?.mode == "stripe" &&
              subscriptions?.mode_id &&
              (subscriptions?.status == "active" ||
                subscriptions?.status == "past_due")) ||
            (subscriptions?.mode == "razorpay" &&
              subscriptions?.mode_id &&
              subscriptions?.status != "canceled");

              console.log("status", status)

          if (!status) {
            commit("SET_SUBSCRIPTION_STATUS", null);
            return;
          }

          let data = {
            current_period_end: subscriptions?.current_period_end?.toDate(),
            current_period_start: subscriptions?.current_period_start?.toDate(),
            subscription_id: subscriptions.mode_id,
            customer_id: subscriptions.stripeId,
            start_date: subscriptions?.start_date?.toDate(),
            voucher_used: subscriptions.voucher_used,
            status: subscriptions?.status,
            hosted_invoice_url: subscriptions.hosted_invoice_url || null, // for stripe
            short_url: subscriptions.short_url || null, // for stripe
            pending_pay_url: subscriptions.pending_pay_url || null, // for razorpay
            plan_subscribed: subscriptions.plan_subscribed,
            merchant_subscribed: subscriptions.merchant_subscribed,
            mode: subscriptions?.mode || null,
          };

          commit("SET_SUBSCRIPTION_STATUS", data);
        } else {
          commit("SET_SUBSCRIPTION_STATUS", null);
        }
      });
    },
    async setTeamMerchants({ commit }, merchants) {
      // for await (merchant of merchants) {
      commit("SET_TEAM_MERCHANTS", null);
      merchants.forEach(async (merchant) => {
        const merchantRef = doc(db_9, "merchants", merchant);
        getDoc(merchantRef).then((doc) => {
          var data = {
            id: merchant,
            name: doc.data()?.business?.brand_name
              ? doc.data().business.brand_name
              : doc.data()?.business.legal_name,
            logo: doc.data()?.business ? doc.data()?.business?.logo : null,
            currency: doc.data()?.business?.country
              ? doc.data().business.country.currency
              : null,
            timezone: doc.data()?.business?.timezone
              ? doc.data()?.business?.timezone
              : null,
            country: doc.data()?.business?.country
              ? doc.data()?.business?.country
              : null,
            rp_account_id: doc.data()?.rp_account_id
              ? doc.data()?.rp_account_id
              : null,
          };
          commit("SET_TEAM_MERCHANTS", data);
        });
      });
    },
    currentTeamMerchant({ commit, dispatch }, merchant) {
      var nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    
          // Create a script element
          var scriptElement = document.createElement('script');
          scriptElement.setAttribute('nonce', nonce);
          scriptElement.src = 'https://desk.zoho.in/portal/api/web/inapp/38442000000923005?orgId=60007735277';
          scriptElement.defer = true;

      if (merchant) {
        dispatch("setSubscribedTools", merchant.id);
        dispatch("setSubscriptionStatus", merchant.id);
        commit("SET_CURRENT_MERCHANT", merchant);
        dispatch("facilities/setFacilities", null, { root: true });
        console.log("merchant >>",merchant.owner_email)
        window.ZohoHCAsapSettings = {
         
          ticketsSettings : {
            preFillFields : {
              "email" : {
                defaultValue : merchant.owner_email
              },
            
              "contactId" : {
                defaultValue : merchant?.business?.brand_name || ''
              }
            }
          },
          organization: "60007735277",
          visitor: {
            name: merchant?.business?.brand_name || '', // Set the visitor's default name
            email: merchant.owner_email // Set the visitor's default email
        }
,    
          preChatForm: {
            show: true,
            name: {
                value: merchant?.business?.brand_name || '', // Default value for the name field
                required: true
            },
          email: {
                value:  merchant.owner_email, // Default value for the email field
                required: true
            }
            // You can add more fields here
        }
         
        };
        document.body.appendChild(scriptElement);
        /*
        if(merchant.owner_email){
         
          axios
          .get(
            process.env.VUE_APP_FIREBASE_FUNCTION_URL +
              `merchant/create-jwt?email=${merchant.owner_email}`,
            
          )
          .then((response) => {
            console.log("INSIDE <<<<<<", response.data)
             // Generate a nonce
 
 // Append the script element to the document body

 
            if (response.data) {
              console.log("INSIDE (currentTeamMerchant) <<", merchant.owner_email)
             
              window.ZohoHCAsapSettings = {
                ticketsSettings : {
                  preFillFields : {
                    "email" : {
                      defaultValue : merchant.owner_email
                    },
                  
                    "contactId" : {
                      defaultValue : merchant?.business?.brand_name || ''
                    }
                  }
                },
                userInfo: {
                  jwtToken: response.data,
                },
              };

              console.log(window)
              document.body.appendChild(scriptElement);
            }else{
              document.body.appendChild(scriptElement);
            }
            
          });
        }*/
        
      }
    },
    currentMerchant({ commit, dispatch, rootState }, merchant) {
   
      var nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    
          // Create a script element
          var scriptElement = document.createElement('script');
          scriptElement.setAttribute('nonce', nonce);
          scriptElement.src = 'https://desk.zoho.in/portal/api/web/inapp/38442000000923005?orgId=60007735277';
          scriptElement.defer = true;
         

      
      if (merchant) {
        dispatch("setSubscribedTools", merchant);
        dispatch("setSubscriptionStatus", merchant);
        const merchantRef = doc(db_9, "merchants", merchant);
        onSnapshot(merchantRef, (doc) => {
          if (doc.exists()) {
            var data = {
              id: merchant,
              name: doc.data()?.business?.brand_name
                ? doc.data().business.brand_name
                : null,
              owner_email: doc.data().owner_email
                ? doc.data().owner_email
                : null,
              logo: doc.data()?.business ? doc.data()?.business?.logo : null,
              currency: doc.data()?.business?.country
                ? doc.data()?.business?.country?.currency
                : null,
              timezone: doc.data()?.business?.timezone
                ? doc.data()?.business?.timezone
                : null,
              country: doc.data()?.business?.country
                ? doc.data()?.business?.country
                : null,
              bank_details: doc.data()?.bank_details
                ? doc.data()?.bank_details
                : null,
              business: doc.data()?.business ? doc.data()?.business : null,
              settings: doc.data()?.settings ? doc.data()?.settings : null,
              team: doc.data()?.team ? doc.data()?.team : null,
              rp_account_id: doc.data()?.rp_account_id
                ? doc.data()?.rp_account_id
                : null,
              stripe_account: doc.data()?.stripe_account
                ? doc.data()?.stripe_account
                : null,
              payment_gateway: doc.data()?.payment_gateway
                ? doc.data()?.payment_gateway
                : null,
              usetiful_completed: doc.data()?.usetiful_completed
                ? doc.data()?.usetiful_completed
                : false,
              usetiful_events: doc.data()?.usetiful_events
                ? doc.data()?.usetiful_events
                : [],
            };
            commit("SET_CURRENT_MERCHANT", data);
            dispatch("facilities/setFacilities", null, { root: true });
            window.ZohoHCAsapSettings = {
              ticketsSettings : {
                preFillFields : {
                  "email" : {
                    defaultValue : data.owner_email
                  },
                
                  "contactId" : {
                    defaultValue : data?.business?.brand_name || ''
                  }
                }
              },
              organization: "60007735277",
          preChatForm: {
            show: true,
            name: {
                value: merchant?.business?.brand_name || '', // Default value for the name field
                required: true
            },
          email: {
                value:  merchant.owner_email, // Default value for the email field
                required: true
            }

          }
            };
            document.body.appendChild(scriptElement);
/*
      if(data.owner_email){
        axios
      .get(
        process.env.VUE_APP_FIREBASE_FUNCTION_URL +
          `merchant/create-jwt?email=${data.owner_email}`,
        
      )
      .then((response) => {
        console.log("INSIDE <<<<<<", response.data)
         // Generate a nonce

// Append the script element to the document body


        if (response.data) {
          console.log("INSIDE (currentMerchant) <<", data.owner_email)
         
          window.ZohoHCAsapSettings = {
            ticketsSettings : {
              preFillFields : {
                "email" : {
                  defaultValue : data.owner_email
                },
              
                "contactId" : {
                  defaultValue : data?.business?.brand_name || ''
                }
              }
            },
            userInfo: {
              jwtToken: response.data,
            },
          };

          console.log(window)
          document.body.appendChild(scriptElement);
        }else{
          document.body.appendChild(scriptElement);
        }
        
      });
      }else{
        document.body.appendChild(scriptElement);
      }
      */

            let authUser = rootState.auth.user;
            let merchants = authUser.merchants
              ? Object.keys(authUser.merchants)
              : [];
            if (!authUser.isOwner && merchants.length > 1) {
              dispatch("setTeamMerchants", merchants);
            }
            userAdoptionMixin.methods.triggerUstiful(data.usetiful_events);
            userAdoptionMixin.methods.triggerCobrowser(data);
           
          }
        });
      }else{
        document.body.appendChild(scriptElement);
      }
    },
    currentMerchantTeam({ commit }, team) {
      if (team) {
        commit("SET_CURRENT_MERCHANT_TEAM", team);
      }
    },
    setIpLocation({ commit }, loc) {
      if (loc) {
        commit("SET_IP_LOCATION", loc);
      }
    },
  },
  getters: {
    getCurrentMerchant(state) {
      return state.currentMerchant;
    },
    getSubscriptionStatus(state) {
      return state.subscriptionStatus;
    },
    getSubscribedTools(state) {
      return state.subscribedTools;
    },
    getTeamMerchants(state) {
      return state.teamMerchants;
    },
    getIpLocation(state) {
      return state.ipLocation;
    },
    getToolsLoaded(state) {
      return state.toolsLoaded;
    },
  },
};

export { merchant };
