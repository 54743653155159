import CobrowseIO from "cobrowse-sdk-js";
import { auth } from "@/firebase/init";
import { db_9 } from "@/firebase/init";
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
export default {
  methods: {
    triggerUsetifulEvent(event_name, element_id) {
      const myEvent = new CustomEvent(event_name, {
        detail: {},
        bubbles: true,
        cancelable: true,
        composed: false,
      });

      let target = document.getElementById(element_id);
      target.addEventListener(event_name, (event) => {
        console.log("The event was triggered");
        this.updateDb(event_name);
      });
      document.querySelector(`#${element_id}`).dispatchEvent(myEvent);
    },

    triggerUstiful(usetiful_events) {
      const usetifulEvents = [
        "shop_created",
        "catalogue_created",
        "source_created",
        "business_timing",
        "team_added",
      ];
      window.usetifulTags = {
        segment:
          usetiful_events.length >= usetifulEvents.length
            ? "old_user"
            : "new_user",
        firstName: "EXAMPLE_NAME",
      };

      var a = document.getElementsByTagName("head")[0];
      var r = document.createElement("script");
      r.async = 1;
      r.src = "https://www.usetiful.com/dist/usetiful.js";
      r.setAttribute("id", "usetifulScript");
      r.dataset.token = "ff3fe956f426cb16a33c62adc4c353cd";
      a.appendChild(r);

      /*  let target = document.getElementsByClassName("uf-close-button");
      console.log("target ==== ", target);
      target.addEventListener("clicked", (event) => {
        console.log("The event was triggered ==== ", event);
      }); */
    },

    triggerCobrowser(merchant) {
      CobrowseIO.license = "ZTCJQ64KdXG8UQ";
      CobrowseIO.start();

      CobrowseIO.customData = {
        user_id: merchant.id,
        user_name: merchant.name,
        user_email: auth.currentUser.email,
        device_id: navigator.userAgent,
        device_name: navigator.platform,
      };
    },

    updateDb(event) {
      //let data = {usetiful_events: }
      const merchantRef = doc(db_9, "merchants", this.currentMerchantId);
      /* setDoc(facilityRef, data, { merge: true })
        .then()
        .catch((error) => {
          console.error("Error updating merchant team: ", error);
        }); */

      updateDoc(merchantRef, {
        usetiful_events: arrayUnion(event),
      });
    },
  },
};
