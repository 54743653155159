import demo_videos from "@/assets/json/demo_videos.json";
import { db_9 } from "@/firebase/init";
import { collection, getDocs, query, where } from "@firebase/firestore";
import moment from "moment";

export default {
  methods: {
    isOpen(businessTimings, currentDate = new Date()) {
      let day = moment(currentDate).format("dddd");
      var selectedDayHours = businessTimings.find((obj) => {
        return obj.day == day;
      });
      if (!selectedDayHours.open) return false;
      if (!selectedDayHours.timings.length) return true;
      for (let i = 0; i < selectedDayHours.timings.length; i++) {
        if (
          !selectedDayHours.timings[i].open ||
          !selectedDayHours.timings[i].close
        ) {
          return true;
        }
        var begin = selectedDayHours.timings[i].open;
        var until = selectedDayHours.timings[i].close;

        var startDate = new Date(currentDate.getTime());
        startDate.setHours(begin.split(":")[0]);
        startDate.setMinutes(begin.split(":")[1]);
        var endDate = new Date(currentDate.getTime());
        endDate.setHours(until.split(":")[0]);
        endDate.setMinutes(until.split(":")[1]);
        if (startDate < currentDate && endDate > currentDate) {
          return true;
        }
      }
      return false;
    },
    toolSubscribed(facilityId, tool) {
      if (
        !this.$store.getters["merchant/getSubscribedTools"] ||
        (this.$store.getters["merchant/getSubscriptionStatus"] || {}).status ==
          "canceled"
      )
        return false;
      let tool_subscribe = this.$store.getters["merchant/getSubscribedTools"][
        facilityId
      ]?.filter((product) => {
        return product.tool === tool;
      });
      if (!tool_subscribe) {
        return false;
      }

      if (tool_subscribe.length) {
        return tool_subscribe[0];
      } else {
        return false;
      }
    },
    readyToCancel(facilityId, tool) {
      let subscriptions = this.toolSubscribed(facilityId, tool);

      const current = new Date();
      var hours = Math.abs(subscriptions.subscribed_on - current) / 36e5;

      return hours > 48;
    },

    castCurrency(num, mode) {
      var number = Number(num);
      if (isNaN(number)) {
        return 0;
      }
      number = number.toFixed(2);
      number = parseFloat(number).toLocaleString();
      switch (mode) {
        case "symbol":
          if (!this.$store.getters["merchant/getCurrentMerchant"].currency)
            return "";
          return (
            this.$store.getters["merchant/getCurrentMerchant"].currency.symbol +
            " " +
            number
          );
        case "zero":
          return number;
        case "both":
          if (!this.$store.getters["merchant/getCurrentMerchant"].currency)
            return "";
          return (
            this.$store.getters["merchant/getCurrentMerchant"].currency.symbol +
            " " +
            number
          );
        default:
          return num;
      }
    },

    getStatusColor(status, type) {
      switch (status) {
        case "new":
          return type == "bg"
            ? "status lighten-1"
            : "status--text text--lighten-1";

        case "confirmed":
          return type == "bg"
            ? "status lighten-2"
            : "status--text text--lighten-2";

        case "processing":
          return type == "bg"
            ? "status lighten-3"
            : "status--text text--lighten-3";
        case "ready-to-deliver":
          return type == "bg"
            ? "status lighten-4"
            : "status--text text--lighten-4";
        case "picked-up":
          return type == "bg"
            ? "status lighten-5"
            : "status--text text--lighten-5";
        case "in-transit":
          return type == "bg"
            ? "status lighten-6"
            : "status--text text--lighten-6";
        case "delivered":
          return type == "bg"
            ? "status lighten-7"
            : "status--text text--lighten-7";

        case "cancelled":
          return type == "bg"
            ? "status lighten-8"
            : "status--text text--lighten-8";

        case "closed":
          return type == "bg"
            ? "status lighten-9"
            : "status--text text--lighten-9";
        default:
          return type == "bg" ? "status " : "status--text ";
      }
    },
    generateRandomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    formatText(text) {
      // Replace underscores with spaces and split the text into words
      let words = text.replace(/_/g, ' ').split(' ');
    
      // Capitalize the first letter of each word
      let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    
      // Join the words back into a single string
      return formattedWords.join(' ');
    },
    generatePdf() {
      setTimeout(() => {
        try {
          if (this.$browserDetect.isFirefox) {
            window.print();
          } else {
            document.execCommand("print", false, null);
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    },

    generateId(prefix = "", str) {
      let first = str.substring(0, 4);
      let last = str.substr(str.length - 4);
      return prefix + first + last;
    },

    async getmerchantTools(merchantId, facilityId) {
      if (!merchantId || !facilityId) {
        return [];
      }
      let m_products = [];
      const q = query(
        collection(db_9, "subscriptions", merchantId, "products"),
        where("facility_id", "==", facilityId)
      );
      let products = await getDocs(q);

      if (!products.empty) {
        products.forEach((product) => {
          m_products.push(product.data().feature);
        });
      }
      return m_products;
    },

    getDemoVideo(name) {
      return demo_videos[name] ? demo_videos[name] : { url: "", title: "" };
    },

    copyAnything(payload) {
      // 1) Add the text to the DOM (usually achieved with a hidden input field)
      const input = document.createElement("input");
      input.setAttribute("id", "payload-input");
      document.body.appendChild(input);
      input.value = payload;

      // 2) Select the text
      input.focus();
      input.select();

      // 3) Copy text to clipboard
      const isSuccessful = document.execCommand("copy");
      document.getElementById("payload-input").remove();

      //this.showSnack("Promo code copied.", "success");
      // 4) Catch errors
      if (!isSuccessful)
        //console.error("Failed to copy text.");
        return false;
      else return true;
    },
  },
  computed: {
    isIndianMerchant() {
      let activeSub = this.$store.getters["merchant/getSubscriptionStatus"];
      let isIndia =
        this.$store.getters["merchant/getCurrentMerchant"]?.country
          .countryCode == "IN";
      if (activeSub) {
        if (
          (activeSub.mode == "razorpay" || activeSub.mode == null) &&
          isIndia
        ) {
          return true;
        } else {
          return false;
        }
      }
      return isIndia;
      /* this.$store.getters['merchant/getSubscriptionStatus'] */
    },
  },
};
