<template>
  <v-app id="app-wrapper">
    <router-view></router-view>
  </v-app>
</template>

<script>
import Header from "@/components/layout/Header.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>
